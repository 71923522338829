import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Component, OnInit } from '@angular/core'
import { ApiService } from '../../services/api.service'
import { DataService } from './data.service'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { Router } from '@angular/router'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { apis } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'

@Component({
    selector: 'app-change-password',
    templateUrl: './update-profile.component.html',
    styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent {
    registrationError: string
    changeParallelForm: FormGroup
    oldPass: any
    newPass: any
    conPass: any
    profileId: any
    inprogress: boolean = false
    breadCrum: any = [
        {
            link: '/admin/change-password',
            value: 'Change Password'
        }
    ]
    constructor(
        private fb: FormBuilder,
        private dataService: DataService,
        public ui: UIHelpers,
        public api: ApiService,
        private router: Router,
        private alert: IAlertService,
        private http: HttpClient
    ) {
        this.changeParallelForm = this.fb.group({
            firstName: new FormControl(null, [Validators.required, Validators.maxLength(200)]),
            lastName: new FormControl(null, [Validators.maxLength(200)]),
           // house: new FormControl(null, [Validators.maxLength(100)]),
            //street: new FormControl(null, [Validators.maxLength(100)]),
            city: new FormControl(null, [Validators.maxLength(100)]),
           // state: new FormControl(null, [Validators.maxLength(100)]),
            zipCode: new FormControl(null, [Validators.maxLength(100)]),
            contact: new FormControl(null, [Validators.required]),
            address: new FormControl(null, [Validators.required]),
            addressTwo: new FormControl(null),
            
        })

        // this.changeParallelForm.patchValue({
        //     name: api.user.parallel.name,
        //     contact: api.user.parallel.contactOne,
        //     address: api.user.parallel.address
        // })

        this.changeParallelForm.patchValue(api.user.parallel)
        this.changeParallelForm.patchValue({contact:api.user.parallel.contactOne})

        this.profileId = api.user.parallel.id
    }

    get g() {
        return this.changeParallelForm.controls
    }

    changeParallel(data: any) {
        this.inprogress = true
        const baseUrl = apis.baseUrl

        let changeParallelData = {
            id: this.profileId,
           ...this.changeParallelForm.value,
           email:this.api.user.email
        }

        if (data.status === 'INVALID') {
            this.alert.error('Please fill-in valid data in all fields & try again.')
            this.inprogress = false

            return
        }

        this.dataService.updateProfile(changeParallelData).subscribe((res: any) => {
            this.inprogress = false
            if (res.success == false) {
                this.alert.error('Parallel cannot change')
            } else {
                this.alert.success('Profile Update successfully')
                this.api.user = res.data
                // this.api.user.parallel.name = changeParallelData.name
                // this.api.user.parallel.contactOne = changeParallelData.contact
                // this.api.user.parallel.address = changeParallelData.address
                // let newDataOfParallel = this.api.user.parallel
                // this.api.user.parallel.name = changeParallelData.name
                localStorage.removeItem('this.api.user')
                localStorage.setItem('user', JSON.stringify(res.data))
                //this.changeParallelForm.reset()
                // this.changeParallelForm.controls.name.setErrors(null)
                // this.changeParallelForm.controls.contact.setErrors(null)
                // this.changeParallelForm.controls.address.setErrors(null)
            }
        })

        //     this.inprogress = false
        //     if (resp.success == false) {
        //         this.registrationError = resp.message
        //         if (resp.message === 'Old password not match') {
        //             this.alert.error('Incorrect Old Password')
        //         }
        //         return
        //     }
        //     this.alert.success('Parallel Change Successfully')
        // })
    }
}
